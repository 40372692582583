import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// To enable direct linking to an entity by id (and refresh)
export default function useEditNavigation<T extends { id: CrunchorderId }>(options: T[]) {
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  let [toEdit, setToEdit] = useState<Partial<T> | null>(null);

  useEffect(() => {
    if (!toEdit && params.id && options.length) {
      let foundOption = options.find(({ id }) => `${id}` === params.id);
      if (!foundOption) {
        navigate(pathname.replace(`/${params.id}`, ""));
      } else {
        setToEdit(foundOption);
      }
    }
  }, [toEdit, params.id, pathname, options, navigate]);

  useEffect(() => {
    if (toEdit?.id && !params.id) {
      setToEdit(null);
    }
  }, [toEdit?.id, params.id]);

  // Handling e.g. click on an entity to edit it
  const handleEditUpdate = useCallback(
    (option: T) => {
      navigate(`${pathname}/${option.id}`);
    },
    [pathname, navigate]
  );

  function handleEditNew(existingOption?: T) {
    if (!existingOption) {
      setToEdit({});
      return;
    }
    let { id, ...optionAsNew } = existingOption;
    setToEdit(optionAsNew as Partial<T>);
  }

  function dismissEdit() {
    setToEdit(null);
    if (params.id) {
      navigate(pathname.replace(`/${params.id}`, ""));
    }
  }

  return {
    toEdit,
    handleEditUpdate,
    handleEditNew,
    dismissEdit,
  };
}
