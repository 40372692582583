import { Component } from "react";

import { Button } from "@crunchit/component-library";
import InsightsTrace from "./ui/InsightsTrace";

import "./ErrorBoundary.scss";

export default class ErrorBoundary extends Component<{ children: any }, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    this.handleButtonClick = this.handleButtonClick.bind(this);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error);
    console.log(errorInfo);
  }

  handleButtonClick() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="page error-boundary">
          <h1 className="error-boundary-title">Beklager, der er sket en fejl</h1>
          <InsightsTrace />
          <Button onClick={this.handleButtonClick}>Prøv igen</Button>
        </div>
      );
    }

    return this.props.children;
  }
}
