import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import { useCustomDispatch } from "store/useStore";
import { userActions } from "store/user";

import useMobileScreensize from "utils/hooks/useMobileScreensize";

import "./List.scss";

const routes = ["setup", "locations", "modules", "features"];

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default function SidebarList() {
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  const { pathname } = useLocation();

  const { isMobileScreensize } = useMobileScreensize();

  let isActiveRoute = (routePath: string) => pathname.includes(`/${routePath}`);

  function handleNavigationClick() {
    if (isMobileScreensize) {
      dispatch(userActions.setSidebarIsOpen(false));
    }
  }

  return (
    <div className="sidebar-list">
      {routes.map((key: string) => {
        let activeClassName = isActiveRoute(key) ? "active" : "";
        return (
          <Link key={key} to={`/${key}`} className={`sidebar-list-item sidebar-${key} ${activeClassName}`} onClick={handleNavigationClick}>
            <div className="sidebar-list-item-content">
              <span className="active-indicator" />
              <div className={`sidebar-icon ${activeClassName}-icon`}></div>
              <p className="sidebar-list-item-name">{t(`navigation:Sections.${capitalize(key)}`)}</p>
            </div>
          </Link>
        );
      })}
    </div>
  );
}
