import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Crunchorder customer data slice */

const initialState: CustomerStore = {
  numberOfRequestsPending: 0, // Triggers a loading wheel - only intended to be used while waiting for updates (not while getting initial data)
  error: false,

  productionUnits: [],
  modules: [],

  productInstanceId: null, // Expecting only 1 per customer

  frontendFeatures: [], // All possible features in frontend settings, and their default values
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setProductionUnits: (state, action: PayloadAction<ProductionUnit[]>) => {
      state.productionUnits = action.payload;
    },
    setModules: (state, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setProductInstanceId: (state, action: PayloadAction<string>) => {
      state.productInstanceId = action.payload;
    },
    setFrontendFeatures: (state, action: PayloadAction<FrontendFeature[]>) => {
      state.frontendFeatures = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      let isLoading = action.payload;

      if (isLoading) {
        state.numberOfRequestsPending += 1;
      } else {
        state.numberOfRequestsPending = state.numberOfRequestsPending - 1 || 0;
      }
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const slice = customerSlice.reducer;
export const actions = customerSlice.actions;
