import { memo } from "react";

import TextField from "@mui/material/TextField";

import InputWrapper from "./Wrapper";

function NumberInput(props: InputFieldProps) {
  const { label, helperText, endAdornment, appendLabel, handleInputChange, ...inputProps } = props;

  // Excluding
  const { format, labelPlacement, ...restProps } = inputProps;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange({ inputName: e.target.name, value: e.target.value });
  };

  return (
    <InputWrapper name={props.name !== props.label ? props.name : ""} helperText={helperText}>
      <TextField {...restProps} label={`${label}${appendLabel || ""}`} type="number" variant="outlined" disabled={restProps.readOnly} fullWidth onChange={onChange} InputProps={{ endAdornment }} />
    </InputWrapper>
  );
}

export default memo(NumberInput);
