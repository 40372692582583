export const isCI = window.location.href.includes(".ci.");
export const isStaging = window.location.href.includes(".staging.");

let environment = "";
if (isCI) {
  environment = "ci.";
}
if (isStaging) {
  environment = "staging.";
}

export const apis = {
  AUTH: process.env.REACT_APP_AUTH_URI || `auth.${environment}crunchy.ninja/api`,
  PRODUCTIONUNIT: process.env.REACT_APP_PRODUCTIONUNIT_URI || `productionunit.${environment}crunchy.ninja/api`,
  MODULE: process.env.REACT_APP_MODULE_URI || `module.${environment}crunchy.ninja/api`,

  CUSTOMERSETTINGS: process.env.REACT_APP_CUSTOMERSETTINGS_URI || `customersetting.${environment}crunchy.ninja/api`,
  PLACEHOLDER: process.env.REACT_APP_PLACEHOLDER_URI || `placeholder.${environment}crunchy.ninja/api`,

  CLUB: process.env.REACT_APP_CLUB_URI || `club2.${environment}crunchy.ninja/api`,

  MENU: process.env.REACT_APP_MENU_URI || `menu.${environment}crunchy.ninja/api`,
  PRODUCT: process.env.REACT_APP_PRODUCT_URI || `product.${environment}crunchy.ninja/api`,
  WAREHOUSE: process.env.REACT_APP_WAREHOUSE_URI || `warehouse.${environment}crunchy.ninja/api`,

  DISCOUNT: process.env.REACT_APP_DISCOUNT_URI || `discount.${environment}crunchy.ninja/api`,
  GIFTCARD: process.env.REACT_APP_GIFTCARD_URI || `giftcard.${environment}crunchy.ninja/api`,

  DELIVERY: process.env.REACT_APP_DELIVERY_URI || `delivery.${environment}crunchy.ninja/api`,
  CAPACITY: process.env.REACT_APP_CAPACITY_URI || `capacity.${environment}crunchy.ninja/api`,

  POS: process.env.REACT_APP_POS_URI || `pos.${environment}crunchy.ninja/api`,

  POSSETTING: process.env.REACT_APP_POSSETTING_URI || `possetting.${environment}crunchy.ninja/api`,
  REFERENCE: process.env.REACT_APP_REFERENCE_URI || `posreference.${environment}crunchy.ninja/api`,

  SALESPORTAL: process.env.REACT_APP_SALESPORTAL_URI || `salesportal.${environment}crunchy.ninja/api`,

  BASKET: process.env.REACT_APP_BASKET_URI || `basket.${environment}crunchy.ninja/api`,
  CHECKOUT: process.env.REACT_APP_CHECKOUT_URI || `checkout.${environment}crunchy.ninja/api`,
  PAYMENT: process.env.REACT_APP_PAYMENT_URI || `payment.${environment}crunchy.ninja/api`,
  ORDER: process.env.REACT_APP_ORDER_URI || `order.${environment}crunchy.ninja/api`,

  ORDERSTATUS: process.env.REACT_APP_ORDERSTATUS_URI || `orderstatus.${environment}crunchy.ninja/api`,
  MESSAGE: process.env.REACT_APP_MESSAGE_URI || `message.${environment}crunchy.ninja/api`,
  KDS: process.env.REACT_APP_KDS_URI || `kds.${environment}crunchy.ninja/api`,

  EPSON: process.env.REACT_APP_EPSON_URI || `epson.${environment}crunchy.ninja/api`,

  IMAGE: process.env.REACT_APP_IMAGE_URI || `image.${environment}crunchy.ninja/api`,
};

export type API_URI = keyof typeof apis;
