import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useSelectOptions from "../forms/useSelectOptions";

import { ModuleType } from "utils/constants/crunchorder";

const isModuleType = (option: any): option is ModuleType => {
  return typeof option === "number";
};

export default function useModuleTypeOptions() {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.values(ModuleType).filter(isModuleType);
  }, []);

  const extendedSelectProps = useMemo(() => {
    const getOptionLabel = (option: ModuleType) => {
      let moduleTypeName = ModuleType[option];
      return t(`options:ModuleTypes.${moduleTypeName}`);
    };

    return {
      options,
      getOptionLabel,
    };
  }, [options, t]);

  return useSelectOptions({ extendedSelectProps });
}
