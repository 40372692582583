let useProfiler = process.env.REACT_APP_USE_PROFILER;

// Reusable callback for Profiler
export function onRender(id: string, phase: string, actualDuration: number, baseDuration: number) {
  // Always logging if the duration is too high during a re-render
  if (phase !== "mount" && baseDuration > 50) {
    // Excluding very small durations
    let durationAsPercentageOfMax = (actualDuration / baseDuration) * 100;
    if (durationAsPercentageOfMax > 75) {
      console.info(`${id}: ${phase} takes ${durationAsPercentageOfMax.toFixed(0)}% of max duration (${baseDuration})`);
    }
  }

  // Additional logging only if enabled
  if (id === "App" || !useProfiler) {
    return;
  }
  // phase: "mount", "update" or "nested-update"
  console.info(`${id} has rendered: ${phase}`);

  // actualDuration: The number of milliseconds spent rendering the <Profiler> and its descendants for the current update.
  // This indicates how well the subtree makes use of memoization and this value should decrease significantly after the initial mount as many of the descendants will only need to re-render if their specific props change.
  console.info(`${id}'s rendering duration: ${actualDuration}`);

  // baseDuration: The number of milliseconds estimating how much time it would take to re-render the entire <Profiler> subtree without any optimizations.
  // This value estimates a worst-case cost of rendering. Compare actualDuration against it to see if memoization is working.
  console.info(`${id}'s worst case duration: ${baseDuration}`);
}

export function getAppInsightsTraceId(): string | undefined {
  let traceId;

  if (window.appInsights && window.appInsights.context && window.appInsights.context.telemetryTrace) {
    traceId = window.appInsights.context.telemetryTrace.traceID;
  }
  return traceId;
}
export function logError(error: any) {
  const isProduction = process.env.NODE_ENV === "production";
  if (!isProduction) {
    console.log(error);
    console.trace(error);
  }
}
