import { useTranslation } from "react-i18next";

import logo from "assets/img/logos/white/logo.png";
import collapsedLogo from "assets/img/logos/logo_collapsed.png";

import { useCustomDispatch } from "store/useStore";
import { useUserSelector, userActions } from "store/user";

import "./Header.scss";

export default function SidebarHeader() {
  const { t } = useTranslation();

  const { sidebarIsOpen } = useUserSelector();
  const dispatch = useCustomDispatch();

  const handleToggleClick = () => dispatch(userActions.setSidebarIsOpen(!sidebarIsOpen));

  return (
    <div className="sidebar-header">
      <div className="logo-container">
        <h2 className="logo-title">{t("common:AppTitle")}</h2>
        <img className="logo" alt="logo" src={logo} loading="lazy" decoding="async" />
        <img className="collapsed-logo" alt="logo" src={collapsedLogo} loading="lazy" decoding="async" />
      </div>
      <div className="sidebar-toggle-icon" onClick={handleToggleClick}></div>
    </div>
  );
}
