import axios, { Method, AxiosResponse, AxiosRequestConfig } from "axios";

import { apis, API_URI } from "utils/constants/apis";
import { logError } from "./logs";

const headers = (token: string, ignoreContentType?: boolean) => {
  return { Authorization: "Bearer " + token, ...(!ignoreContentType && { "Content-Type": "application/json" }) };
};

async function request<T>(method: Method, token: string, api: API_URI, path: string, body?: RequestBody, ignoreContentType?: boolean): Promise<HttpResponse<T>> {
  let url = `https://${apis[api]}${path}`;

  try {
    let requestConfig: AxiosRequestConfig = {
      method: method,
      timeout: 45000,
      url: url,
      data: body,
      headers: headers(token, ignoreContentType),
    };

    let requestResponse: AxiosResponse = await axios(requestConfig);

    return { data: requestResponse.data, isErrorResponse: () => false };
  } catch (error) {
    logError(error);
    let errorText = "";
    let errorCode: number | undefined;

    if (axios.isAxiosError(error)) {
      // Axios error message
      if (error.response?.statusText) {
        errorText = error.response.statusText;
      }
      // CO error object
      if (error.response?.data?.errors) {
        let errorObject = error.response.data as COError;

        if (errorObject.errors) {
          try {
            for (let key in errorObject.errors) {
              let coErrorTexts: string[] = errorObject.errors[key];
              errorText += errorText ? ": \n" : "";
              errorText += `${key}: ${coErrorTexts.join(", ")}`;
            }
          } catch {}
        }
      }
      errorText = errorText || "An error occurred";
      errorCode = error.response?.status;
    }
    return { errorText, errorCode, isErrorResponse: () => true };
  }
}

function get<T>(token: string, api: API_URI, path: string): Promise<HttpResponse<T>> {
  return request("get", token, api, path);
}
function post<T>(token: string, api: API_URI, path: string, body: RequestBody, ignoreContentType?: boolean): Promise<HttpResponse<T>> {
  return request("post", token, api, path, body, ignoreContentType);
}
function put<T>(token: string, api: API_URI, path: string, body: RequestBody): Promise<HttpResponse<T>> {
  return request("put", token, api, path, body);
}
function patch<T>(token: string, api: API_URI, path: string, body: RequestBody): Promise<HttpResponse<T>> {
  return request("patch", token, api, path, body);
}
function del<T>(token: string, api: API_URI, path: string): Promise<HttpResponse<T>> {
  return request("delete", token, api, path);
}

export { get, post, put, patch, del };
