import { ReactNode } from "react";

export default function FormSection({ title, className = "", children }: { title?: string; className?: string; children: ReactNode[] | ReactNode }) {
  return (
    <div className={`form-section ${className}`}>
      {title && <h3 className="form-section-title">{title}</h3>}

      <div className="form-inputs">{children}</div>
    </div>
  );
}
