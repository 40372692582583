import { useCallback, useState } from "react";

import { isValidInput } from "utils/helpers/forms";

interface InputValidationHookProps {
  type: string;
  isRequired?: boolean;
}

const isEmptyInput = (value?: any) => value === undefined || value === null || value.length === 0;

export default function useInputValidation(props: InputValidationHookProps) {
  let [type] = useState(props.type);
  let [isRequired] = useState(props.isRequired);

  const validateInputValue = useCallback(
    (value?: any) => {
      let isEmpty = isEmptyInput(value);

      let isValid = true;
      if (!isEmpty) {
        isValid = isValidInput({ type, value });
      }

      // If required and empty, it's invalid
      if (isRequired && isEmpty) {
        isValid = false;
      }
      return !isValid;
    },
    [type, isRequired]
  );

  const withInputValidation = useCallback(
    (change: FormInputChange): FormInputChange => {
      let hasError = validateInputValue(change.value);
      return { ...change, hasError };
    },
    [validateInputValue]
  );

  return {
    validateInputValue,
    withInputValidation,
  };
}
