import { MouseEventHandler, ButtonHTMLAttributes } from "react";

import "./TextButton.scss";

type Size = "xs" | "sm" | "md" | "lg";

interface Props {
  children: string | JSX.Element;
  size?: Size;
  className?: string;
  attributes?: ButtonHTMLAttributes<HTMLButtonElement>;
  onClick?: MouseEventHandler;
}

export default function TextButton({ children, attributes = {}, size = "md", className = "", onClick }: Props) {
  return (
    <button className={`button text-button size-${size} ${className}`.trim()} {...attributes} onClick={onClick}>
      {children}
    </button>
  );
}
