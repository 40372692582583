import Environment from "./Environment";
import SidebarHeader from "./Header";
import SidebarList from "./List";
import SidebarLocation from "./Location";

import "./Sidebar.scss";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <Environment />
      <SidebarHeader />
      <SidebarLocation />
      <SidebarList />
    </div>
  );
}
