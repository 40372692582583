import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "@mui/material";
import InsightsTrace from "../InsightsTrace";

const styleOverride = { marginBottom: 2 };

export default function FormStatus({ isSuccess, isError, isShowingValidationErrors, statusText }: FormStatusProps) {
  const { t } = useTranslation();

  let [hideAlert, setHideAlert] = useState(false);

  useEffect(() => {
    setHideAlert(false);
  }, [isSuccess, isError, isShowingValidationErrors, statusText]);

  if (!hideAlert) {
    if (isSuccess) {
      return (
        <Alert severity="success" sx={styleOverride} onClose={() => setHideAlert(true)}>
          {statusText || `${t("actions:SaveSuccessful")}!`}
        </Alert>
      );
    }
    if (isError) {
      return (
        <>
          <Alert severity="error" sx={styleOverride} onClose={() => setHideAlert(true)}>
            {statusText || t("common:Error")}
          </Alert>
          <InsightsTrace />
        </>
      );
    }
    if (isShowingValidationErrors) {
      return (
        <Alert severity="warning" sx={styleOverride} onClose={() => setHideAlert(true)}>
          {statusText || t("common:InputWarning")}
        </Alert>
      );
    }
  }

  return <></>;
}
