import { useCallback } from "react";

const noMap = (value: any) => value;

interface SelectOptionsProps {
  extendedSelectProps: CustomAutocompleteProps;
  mapToValue?: (value: any) => any;
  mapDefaultValue?: (value: any) => any;
}

// Generic hook for options data - takes functions for custom data
export default function useSelectOptions(props: SelectOptionsProps) {
  const { extendedSelectProps, mapToValue = noMap, mapDefaultValue = noMap } = props;

  const getSelectDefaultValue = useCallback(
    (defaultValue: any) => {
      if (Array.isArray(defaultValue)) {
        return defaultValue.map(mapDefaultValue);
      }
      return mapDefaultValue(defaultValue);
    },
    [mapDefaultValue]
  );

  const getSelectedOptionValue = useCallback(
    (value?: any) => {
      if (!value) {
        return value;
      }

      if (Array.isArray(value)) {
        let list = value;
        list = list.map(mapToValue);
        return list;
      }

      return mapToValue(value);
    },
    [mapToValue]
  );

  // Additional props for options
  const withOptions = useCallback(
    (inputFieldProps: InputFieldProps): InputFieldProps => {
      return {
        ...inputFieldProps,
        selectProps: { ...inputFieldProps.selectProps, ...extendedSelectProps },
      };
    },
    [extendedSelectProps]
  );

  return { withOptions, getSelectedOptionValue, getSelectDefaultValue };
}
