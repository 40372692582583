export enum ModuleType {
  "CLICKANDCOLLECT" = 0,
  "TAKEAWAY" = 1,
  "ORDERATTABLE" = 2,
  "KIOSK" = 4,
  "LANDINGPAGE" = 5,
  "FASTTRACK" = 6,
  "ROOMSERVICE" = 7,
  "THIRDPARTY_LAVU" = 8,
  "THIRDPARTY_POSCONNECT" = 9,
  "EVENT" = 10,
  "SALESPORTAL" = 11,
}

export const thirdPartyModuleTypes = ["THIRDPARTY_LAVU", "THIRDPARTY_POSCONNECT", "SALESPORTAL"];

export enum PosSystemType {
  "LAVU" = 0,
  "POSCONNECT" = 1,
  "WOLT" = 2,
  "ONLINEPOS" = 3,
}

export enum CaptureSetting {
  "CHECKOUT" = 1,
  "ORDERCOMPLETE" = 2,
  "ORDERREADYDATE" = 3,
  "MANUAL" = 4,
  "THIRDPARTY" = 5,
}

export enum KdsViewOption {
  "PRODUCT" = 0,
  "INGREDIENT" = 1,
}

export enum DeliveryType {
  "ZIPCODE" = 0,
  "DISTANCE" = 1,
}

export const features: FeatureName[] = ["product", "menu", "discount", "delivery", "capacity", "checkout", "giftcard", "payment", "pos", "posSetting", "club", "placeholder", "salesPortal"];
