import { useMemo } from "react";
import { useCustomSelector } from "store/useStore";

// Computing values from redux state
export default function useSelector() {
  const state = useCustomSelector((state) => state);

  const appState = state.app;

  const { numberOfRequestsPending: customerRequestsPending } = state.customer;
  const { numberOfRequestsPending: userRequestsPending } = state.user;

  let isLoading = useMemo(() => customerRequestsPending > 0 || userRequestsPending > 0, [customerRequestsPending, userRequestsPending]);

  return {
    ...appState,
    isLoading,
  };
}
