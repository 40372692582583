import { useCallback, useEffect, useState } from "react";

const tabsListId = "tabs";

export default function useTabScroll() {
  const [showLeftScrollButton, setShowLeftScrollButton] = useState(false);
  const [showRightScrollButton, setShowRightScrollButton] = useState(false);

  // If at the end of the list, hide the scroll button
  const updateScrollButtons = useCallback((element: HTMLElement) => {
    const width = element?.clientWidth || 0;
    const scrollWidth = element?.scrollWidth || 0;
    const scrollPosition = element?.scrollLeft || 0;

    const endScrollPosition = scrollWidth - width;

    setShowLeftScrollButton(scrollPosition !== 0);
    setShowRightScrollButton(scrollPosition < endScrollPosition);
  }, []);

  // If at the end of the list, hide the scroll button
  const onScroll = useCallback(
    (event: Event) => {
      let tabsListElement = event.target as HTMLElement;
      if (!tabsListElement) {
        return;
      }
      updateScrollButtons(tabsListElement);
    },
    [updateScrollButtons]
  );

  useEffect(() => {
    let el = document.getElementById(tabsListId);
    el?.addEventListener("scroll", onScroll);

    return () => {
      el?.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  // On mount, check if buttons should show
  useEffect(() => {
    let el = document.getElementById(tabsListId);
    if (el) {
      updateScrollButtons(el);
    }
  }, [updateScrollButtons]);

  function handleScrollButtonClick(direction: "right" | "left") {
    let el = document.getElementById(tabsListId);
    if (!el) {
      return;
    }

    if (direction === "left") {
      el.scrollTo({ left: 0, behavior: "smooth" });
      setShowLeftScrollButton(false);
    }

    if (direction === "right") {
      const width = el.clientWidth;
      const scrollWidth = el.scrollWidth;
      const endScrollPosition = scrollWidth - width;

      el.scrollTo({ left: endScrollPosition, behavior: "smooth" });
      setShowRightScrollButton(false);
    }
  }

  return {
    showRightScrollButton,
    showLeftScrollButton,

    handleScrollButtonClick,
  };
}
