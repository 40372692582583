import { memo } from "react";

import { FormControlLabel, RadioGroup, Radio } from "@mui/material";

import InputWrapper from "./Wrapper";

function RadioInput(props: InputFieldProps & { selectProps: CustomAutocompleteProps }) {
  const { name, helperText, defaultValue, selectProps, handleInputChange } = props;
  const { options, loading, direction, getOptionLabel, getSelectedOptionValue, getSelectDefaultValue } = selectProps;

  // Radio option values are always strings
  const getValue = (value: string) => {
    let val = value;
    try {
      val = JSON.parse(value);
    } catch {}
    return getSelectedOptionValue ? getSelectedOptionValue(val) : val;
  };

  if (!options || loading) {
    return <></>;
  }

  return (
    <InputWrapper name={""} helperText={helperText}>
      <RadioGroup
        row={direction === "row"}
        defaultValue={getSelectDefaultValue && defaultValue ? getSelectDefaultValue(defaultValue) : defaultValue}
        onChange={(event, newValue) => handleInputChange({ inputName: name, value: getValue(newValue) })}
      >
        {options.map((option, index) => {
          return <FormControlLabel key={`radio-option-${index}`} value={option} label={getOptionLabel ? getOptionLabel(option) : option} control={<Radio />} />;
        })}
      </RadioGroup>
    </InputWrapper>
  );
}

export default memo(RadioInput);
