import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import appReducer from "./app";
import userReducer from "./user";
import customerReducer from "./customer";

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    customer: customerReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
