import { useTranslation } from "react-i18next";

import EditModal from "components/ui/modals/EditModal";
import Form from "components/ui/Form";

import useFormStatus from "utils/hooks/forms/useFormStatus";
import useFormInputs from "utils/hooks/forms/useFormInputs";
import useUpdateRequestFromInput from "utils/hooks/http/useUpdateRequestFromInput";

import { languageOptions } from "utils/constants/options";

const translationKey = "setup:Language.Form.InputLabels";

export default function Edit(props: { initialValue: Partial<LanguageSetting> }) {
  const { initialValue } = props;

  const { t } = useTranslation();

  const { formStatus, formStatusHandler } = useFormStatus();
  const { isShowingValidationErrors } = formStatus;
  const { inputProps, getEditedKeys, getInputErrors, getEditedValue } = useFormInputs({ initialValue, translationKey });
  const { sendUpdateRequest } = useUpdateRequestFromInput({ api: "CUSTOMERSETTINGS", path: "/Language" });

  let handleSave = async () => {
    formStatusHandler.reset();
    if (getEditedKeys().length === 0) {
      return;
    }
    if (getInputErrors().length > 0) {
      formStatusHandler.setStatus({ isShowingValidationErrors: true });
      return;
    }

    let response = await sendUpdateRequest<LanguageSetting>({ editedValue: getEditedValue(), editedKeys: getEditedKeys() });
    if (!response.isErrorResponse()) {
      formStatusHandler.setStatus({ isSuccess: true });
    } else {
      formStatusHandler.setStatus({ isError: true, statusText: response.errorText });
    }
  };

  return (
    <EditModal title={t("setup:Language.ModalTitle")}>
      <Form isShowingValidationErrors={isShowingValidationErrors}>
        <Form.Section>
          <Form.Input name="id" type="number" readOnly {...inputProps} />
          <Form.Input name="defaultLanguage" type="select" required selectProps={{ options: languageOptions }} {...inputProps} />
          <Form.Input name="languages" type="select" required selectProps={{ options: languageOptions, multiple: true }} {...inputProps} />
        </Form.Section>
        <Form.Actions formStatus={formStatus} handleSaveClick={handleSave} />
      </Form>
    </EditModal>
  );
}
