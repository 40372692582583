import { useEffect } from "react";
import { useNavigate, useOutlet } from "react-router-dom";

import Page from "components/Page";

import NavigationTabs from "components/ui/navigation/Tabs";

import tabs from "./tabs";

export default function Setup() {
  const outlet = useOutlet();
  const navigate = useNavigate();

  useEffect(() => {
    if (!outlet) {
      navigate(tabs[0].path);
    }
  }, [outlet, navigate]);

  return (
    <Page titleKey="setup:PageTitle" subheader={<NavigationTabs tabs={tabs} />}>
      <div className="page-content setup">{outlet}</div>
    </Page>
  );
}
