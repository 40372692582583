import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { da } from "./languages/da-DK";
import { en } from "./languages/en-US";

const resources = {
  "da-DK": da,
  "en-US": en,
};

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  resources,

  lng: "da-DK",
  fallbackLng: "da-DK",

  ns: ["common", "actions", "navigation", "options", "home", "setup", "locations", "modules", "features"],
  defaultNS: "common",

  supportedLngs: ["da-DK", "en-US"],
  load: "currentOnly",

  react: {
    useSuspense: true,
  },

  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});

export default i18n;
