import { memo, useMemo } from "react";

import TextField from "@mui/material/TextField";

import InputWrapper from "./Wrapper";

function TextInput(props: InputFieldProps) {
  const { label, helperText, endAdornment, appendLabel, handleInputChange, ...inputProps } = props;

  // Excluding
  const { format, labelPlacement, ...restProps } = inputProps;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange({ inputName: e.target.name, value: e.target.value });
  };

  const inputLabelProps = useMemo(() => {
    if (props.value) {
      // Fix for mui bug after a controlled component updates
      return { shrink: true };
    }
    return undefined;
  }, [props.value]);

  return (
    <InputWrapper name={props.name !== props.label ? props.name : ""} helperText={helperText}>
      <TextField
        {...restProps}
        label={`${label}${appendLabel || ""}`}
        type="text"
        variant="outlined"
        disabled={restProps.readOnly}
        fullWidth
        onChange={onChange}
        InputProps={{ endAdornment }}
        InputLabelProps={inputLabelProps}
      />
    </InputWrapper>
  );
}

export default memo(TextInput);
