import { ReactNode } from "react";

import { FormControl } from "@mui/material";

import FormSection from "./Section";
import FormInput from "./Input";
import FormActions from "./Actions";

import "./Form.scss";

function Form({ isShowingValidationErrors, children }: { isShowingValidationErrors?: boolean; children: ReactNode }) {
  return (
    <FormControl fullWidth error={!!isShowingValidationErrors}>
      <div className="form">{children}</div>
    </FormControl>
  );
}

Form.Section = FormSection;

Form.Input = FormInput;

Form.Actions = FormActions;

export default Form;
