import { memo } from "react";

import { FormControlLabel, Switch } from "@mui/material";

import InputWrapper from "./Wrapper";

function ToggleInput(props: InputFieldProps) {
  const { defaultValue, label, helperText, labelPlacement, appendLabel, handleInputChange, ...inputProps } = props;

  // Excluding
  const { error, format, placeholder, ...restProps } = inputProps;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange({ inputName: e.target.name, value: e.target.checked });
  };

  return (
    <InputWrapper name={props.name !== label ? props.name : ""} helperText={helperText}>
      <FormControlLabel
        label={`${label}${appendLabel || ""}`}
        labelPlacement={labelPlacement}
        control={<Switch {...restProps} defaultChecked={!!defaultValue} disabled={restProps.readOnly} onChange={onChange} />}
      />
    </InputWrapper>
  );
}

export default memo(ToggleInput);
