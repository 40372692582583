import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Snackbar } from "@mui/material";

import { getAppInsightsTraceId } from "utils/helpers/logs";
import { copyTextToClipboard, selectText } from "utils/helpers/misc";

import "./InsightsTrace.scss";

export default function InsightsTrace() {
  const { t } = useTranslation();

  let [successPromptIsOpen, setSuccessPromptIsOpen] = useState(false);

  let appInsightsTraceId = getAppInsightsTraceId();

  async function handleClick(element: HTMLParagraphElement) {
    if (appInsightsTraceId && element) {
      selectText(element);

      let successfulCopy = await copyTextToClipboard(appInsightsTraceId);
      setSuccessPromptIsOpen(successfulCopy);
    }
  }

  if (appInsightsTraceId) {
    return (
      <>
        <p onClick={(e) => handleClick(e.currentTarget)} className="muted-text insights-trace-text">
          {t("common:InsightsId")}: {appInsightsTraceId}
        </p>
        <Snackbar
          open={successPromptIsOpen}
          autoHideDuration={1500}
          message={`${t("common:Copied")}!`}
          onClose={() => setSuccessPromptIsOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      </>
    );
  }

  return <></>;
}
