import { ReactNode } from "react";
import { userActions, useUserSelector } from "store/user";
import { useCustomDispatch } from "store/useStore";

import "./Header.scss";

interface HeaderProps {
  title: string;
  subheader?: ReactNode;
}

export default function Header({ title, subheader }: HeaderProps) {
  const { sidebarIsOpen } = useUserSelector();

  const dispatch = useCustomDispatch();

  const handleOpenSidebarClick = () => dispatch(userActions.setSidebarIsOpen(true));

  return (
    <div className="header">
      {!sidebarIsOpen && <div className="burger-icon" onClick={handleOpenSidebarClick}></div>}
      <h2 className="header-title">{title}</h2>
      {!!subheader && <div className="subheader-content">{subheader}</div>}
    </div>
  );
}
