import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface PopupState {
  title: string;
  content: string;
  handleConfirmClick: () => void;
}

export default function usePopup() {
  const { t } = useTranslation();

  let [popupState, setPopupState] = useState<PopupState>();
  let popupRef = useRef<PopupHandler>(null);

  function showPopup(state: PopupState) {
    setPopupState(state);
    popupRef.current?.open();
  }

  // Predefined popup content
  function showConfirmDismissPopup(callback: () => void) {
    setPopupState({
      title: t("actions:Popup.ConfirmDismissForm.Title"),
      content: t("actions:Popup.ConfirmDismissForm.Text"),
      handleConfirmClick: callback,
    });
    popupRef.current?.open();
  }

  function showConfirmDeletePopup(callback: () => void) {
    setPopupState({
      title: t("actions:Popup.ConfirmDelete.Title"),
      content: t("actions:Popup.ConfirmDelete.Text"),
      handleConfirmClick: callback,
    });
    popupRef.current?.open();
  }

  return {
    popupRef,
    popupState,
    showPopup,
    showConfirmDismissPopup,
    showConfirmDeletePopup,
  };
}
