export function isObject(value: any) {
  return value != null && value.constructor.name === "Object";
}

export function sortAlphabetically(a?: string, b?: string) {
  return (a?.toLowerCase() || "").localeCompare(b?.toLowerCase() || "");
}

export function capitalize(text: string) {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

export function selectText(node: HTMLElement) {
  if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection?.removeAllRanges();
    selection?.addRange(range);
  }
}

function fallbackCopyToClipboard(text: string): boolean {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let successfulCopy = false;
  try {
    successfulCopy = document.execCommand("copy");
  } catch (err) {}
  document.body.removeChild(textArea);
  return successfulCopy;
}

export async function copyTextToClipboard(text: string): Promise<boolean> {
  return new Promise((resolve) => {
    if (!navigator.clipboard) {
      resolve(fallbackCopyToClipboard(text));
    }

    navigator.clipboard.writeText(text).then(
      function () {
        resolve(true);
      },
      function (err) {
        resolve(false);
      }
    );
  });
}

/**
 * Replaces all instanceIds with the new instanceId, in case this was created from an existing set of settings
 */
export function withNewInstanceId(instanceId: string, settings: Object) {
  let { id, ...cleanSettings } = settings as any;

  for (const [key, value] of Object.entries(settings)) {
    if (key === "instanceId") {
      cleanSettings[key] = instanceId;
    }
    // Nested objects are more settings that also require replacement of instanceId
    if (isObject(value)) {
      cleanSettings[key] = withNewInstanceId(instanceId, value);
    }
    // For arrays, we clear both id and instanceId
    if (Array.isArray(value) && isObject(value[0])) {
      cleanSettings[key] = value.map((val) => {
        let { id, instanceId, ...cleanValue } = val;
        return cleanValue;
      });
    }
  }
  return cleanSettings;
}

export function getNestedValue(obj: any, path: string): any {
  let nestedKeys = path.split("/");
  let key = nestedKeys.shift();

  if (!key || !obj) {
    return obj;
  }
  return getNestedValue(obj[key], nestedKeys.join("/"));
}

export function setNestedValue(obj: any, path: string, value: any) {
  let nestedKeys = path.split("/");
  let key = nestedKeys.shift();

  if (!key) {
    return value;
  }

  obj[key] = setNestedValue(obj[key] || {}, nestedKeys.join("/"), value);
  return obj;
}
