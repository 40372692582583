const tabs: NavigationTab[] = [
  {
    name: "KDS",
    labelKey: "setup:KDS.TabTitle",
    path: "/setup/kds",
  },
  {
    name: "Language",
    labelKey: "setup:Language.TabTitle",
    path: "/setup/language",
  },
  {
    name: "Printer",
    labelKey: "setup:Printer.TabTitle",
    path: "/setup/printer",
  },
  {
    name: "Epson",
    labelKey: "setup:Epson.TabTitle",
    path: "/setup/epson",
  },
  {
    name: "Comasys",
    labelKey: "setup:Comasys.TabTitle",
    path: "/setup/comasys",
  },
];

export default tabs;
