import { useCallback, useMemo, useState } from "react";

let initialState: FormStatusProps = { isShowingValidationErrors: false, isSuccess: false, isError: false };

export default function useFormStatus() {
  let [formStatus, setFormStatus] = useState<FormStatusProps>(initialState);

  let reset = useCallback(() => setFormStatus(initialState), []);
  let setStatus = useCallback((value: FormStatusProps) => setFormStatus(value), []);

  let formStatusHandler = useMemo(() => ({ reset, setStatus }), [reset, setStatus]);

  return {
    formStatus,
    formStatusHandler,
  };
}
