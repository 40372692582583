import { useCallback, useEffect, useMemo } from "react";

import useSelectOptions from "../forms/useSelectOptions";
import useAsyncGetRequest from "../http/useAsyncGetRequest";

export default function useModuleIdOptions() {
  const { value: modules = [], valueIsLoading: isLoading, updateValue: updateModules } = useAsyncGetRequest<Module[]>({ api: "MODULE", path: "/Module/modules" });

  useEffect(() => {
    updateModules();
  }, [updateModules]);

  // Mapping selected option to correct format for save
  const mapToValue = useCallback((value: any) => {
    let module = value as Module;
    return module.id;
  }, []);

  // Get default selected option
  const mapDefaultValue = useCallback((value: any) => {
    return { id: value };
  }, []);

  const extendedSelectProps = useMemo(() => {
    const getOptionLabel = (option: Module) => {
      // Looking up the value, to ensure defaultValue label updates when data is loaded
      let displayOption = modules.find((module) => module.id === option.id) || option;
      return displayOption.name || `${displayOption.id}`;
    };

    const isOptionEqualToValue = (option: Module, val: Module) => option.id === val.id;

    return {
      options: modules,
      loading: isLoading,
      getOptionLabel,
      isOptionEqualToValue,
    };
  }, [modules, isLoading]);

  return useSelectOptions({ extendedSelectProps, mapToValue, mapDefaultValue });
}
