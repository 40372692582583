import { memo, useMemo, useState } from "react";
import dayjs from "dayjs";

import TextField from "@mui/material/TextField";

import InputWrapper from "./Wrapper";

let inputDateFormat = "YYYY-MM-DD"; // Required format for valid input

function DateInput(props: InputFieldProps) {
  const { format, label, defaultValue, helperText, endAdornment, appendLabel, handleInputChange, ...inputProps } = props;

  // Excluding
  const { labelPlacement, ...restProps } = inputProps;

  const [dateFormat] = useState(format); // The format to return on change

  const formattedDefaultValue = useMemo(() => {
    if (!defaultValue) {
      return defaultValue;
    }
    return dayjs(defaultValue, dateFormat).format(inputDateFormat);
  }, [defaultValue, dateFormat]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedDate = e.target.value;
    let formattedDateValue = selectedDate ? dayjs(e.target.value).format(dateFormat) : "";
    handleInputChange({ inputName: e.target.name, value: formattedDateValue });
  };

  return (
    <InputWrapper name={props.name !== props.label ? props.name : ""} helperText={helperText}>
      <TextField
        {...restProps}
        type="date"
        label={`${label}${appendLabel || ""}`}
        defaultValue={formattedDefaultValue}
        variant="outlined"
        disabled={restProps.readOnly}
        fullWidth
        onChange={onChange}
        InputProps={{ endAdornment }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </InputWrapper>
  );
}

export default memo(DateInput);
