import { useCustomSelector } from "store/useStore";

// Computing values from redux state
export default function useSelector() {
  const state = useCustomSelector((state) => state.user);

  const { numberOfRequestsPending } = state;

  return {
    ...state,
    isLoading: numberOfRequestsPending > 0,
  };
}
