import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { Button } from "@crunchit/component-library";

export default function SidebarLocation() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };

  return (
    <div className="sidebar-location">
      <Button size="xs" onClick={handleButtonClick}>
        {t("navigation:ChangeCustomer")}
      </Button>
    </div>
  );
}
