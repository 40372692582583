import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "utils/helpers/localStorage";

/* Global app slice */

const initialState: AppStore = {
  token: getFromLocalStorage("customerToken") || "",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      let token = action.payload;
      state.token = token;
      if (token) {
        saveToLocalStorage("customerToken", token);
      } else {
        removeFromLocalStorage("customerToken");
      }
    },
  },
});

export const slice = appSlice.reducer;
export const actions = appSlice.actions;
