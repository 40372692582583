import { useCallback, useMemo, useRef } from "react";
import { getNestedValue, setNestedValue } from "utils/helpers/misc";

export default function useFormInputs<T extends object>(props: { initialValue: Partial<T>; translationKey?: string }) {
  const { initialValue, translationKey } = props;

  let inputRefs = useRef<Partial<{ [key in keyof T]: FormInputRef }>>({});

  const addRef = useCallback((ref: FormInputRef | null) => ref && (inputRefs.current[ref.name as keyof T] = ref), []);

  const getDefaultValue = useCallback((inputName: string) => initialValue && getNestedValue(initialValue, inputName), [initialValue]);

  const inputProps = useMemo(
    () => ({
      ref: addRef,
      getDefaultValue,
      translationKey,
    }),
    [addRef, getDefaultValue, translationKey]
  );

  const getKeys = useCallback((args: { onlyEditedKeys: boolean }) => {
    const { onlyEditedKeys } = args;

    let keys: string[] = [];

    if (!inputRefs.current) {
      return keys;
    }

    for (const key of Object.keys(inputRefs.current)) {
      let inputRef = inputRefs.current[key as keyof typeof inputRefs.current];
      if (inputRef) {
        if (!onlyEditedKeys || inputRef.hasEdits) {
          keys.push(inputRef.name);
        }
      }
    }

    return keys;
  }, []);

  const getEditedKeys = useCallback(() => getKeys({ onlyEditedKeys: true }), [getKeys]);

  const getAllKeys = useCallback(() => getKeys({ onlyEditedKeys: false }), [getKeys]);

  const getInputErrors = useCallback(() => {
    let keys: string[] = [];

    if (!inputRefs.current) {
      return keys;
    }

    for (const key of Object.keys(inputRefs.current)) {
      let inputRef = inputRefs.current[key as keyof typeof inputRefs.current];
      if (inputRef?.hasError) {
        keys.push(inputRef.name);
      }
    }

    return keys;
  }, []);

  const getEditedValue = useCallback(
    (params?: { selectedIntegrationSetting?: string; forceOneOfSetting?: string[] }): Partial<T> => {
      const { selectedIntegrationSetting, forceOneOfSetting = [] } = params || {};

      let editedValue = { ...initialValue } || ({} as Partial<T>);

      if (!inputRefs.current) {
        return editedValue;
      }

      const getInputValue = (key: string) => inputRefs.current[key as keyof typeof inputRefs.current]?.value;
      for (const key of Object.keys(inputRefs.current)) {
        editedValue = setNestedValue(editedValue, key, getInputValue(key));
      }

      // Override nested settings that should be null if not selected
      forceOneOfSetting.forEach((integrationSettingKey) => {
        if (integrationSettingKey !== selectedIntegrationSetting) {
          let settingKey = integrationSettingKey as keyof typeof editedValue;
          editedValue[settingKey] = null as T[keyof T];
        }
      });

      return editedValue;
    },
    [initialValue]
  );

  return {
    inputProps,
    inputRefs,

    getDefaultValue,
    getEditedValue,
    getEditedKeys,
    getAllKeys,
    getInputErrors,
  };
}
