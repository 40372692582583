import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import Modal from "./Modal";

import arrowLeftIcon from "assets/img/icons/black/arrow-left.svg";
import exitIcon from "assets/img/icons/black/close.svg";

import "./EditModal.scss";

interface EditModalProps {
  title: string;
  goBackText?: string;
  children: ReactNode;

  handleBackClick?: () => void;
}

export default function EditModal({ title, goBackText, children, handleBackClick }: EditModalProps) {
  const { t } = useTranslation();

  return (
    <Modal modalType="edit">
      {handleBackClick !== undefined && (
        <Modal.Header>
          <div className="modal-navigation back" onClick={handleBackClick}>
            <img src={arrowLeftIcon} alt="back" loading="lazy" decoding="async" />
            <p className="navigation-text">{goBackText || t("actions:Back")}</p>
          </div>
          <div className="modal-navigation exit" onClick={handleBackClick}>
            <img src={exitIcon} alt="back" loading="lazy" decoding="async" />
          </div>
        </Modal.Header>
      )}
      <Modal.Body>
        <h3 className="modal-content-title">{title}</h3>
        {children}
      </Modal.Body>
    </Modal>
  );
}
