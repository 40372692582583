import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  breakpoints: {
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1500 },
  },
  palette: {
    primary: {
      main: "#0051ff",
    },
    secondary: {
      main: "#1b2140",
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

// Component styling overrides
theme.components = {
  MuiDivider: {
    styleOverrides: {
      root: {
        marginTop: "32px",
        marginBottom: "32px",
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        boxShadow: "0px 0px 10px #1b214026",
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        [theme.breakpoints.up("md")]: {
          borderRadius: "16px",
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      message: {
        whiteSpace: "pre",
      },
    },
  },
};

export default theme;
