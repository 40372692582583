import { isCI, isStaging } from "utils/constants/apis";

import "./Environment.scss";

export default function Environment() {
  let isVisible = isCI || isStaging;

  return (
    <>
      {isVisible && (
        <div className="environment-indicator">
          {isCI && <p className="environment-name">CI</p>}
          {isStaging && <p className="environment-name">STAGING</p>}
        </div>
      )}
    </>
  );
}
