import { useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import Dialog from "@mui/material/Dialog";

import TextButton from "./TextButton";
import { Button } from "@crunchit/component-library";

import useMobileScreensize from "utils/hooks/useMobileScreensize";

import "./Popup.scss";

declare interface PopupProps {
  state?: {
    title: string;
    content: string;
    handleConfirmClick: () => void;
  };
}

const Popup = forwardRef<PopupHandler, PopupProps>((props, ref) => {
  const { state } = props;

  const { t } = useTranslation();

  let [isOpen, setIsOpen] = useState(false);

  // Fullscreen for mobile screens
  const { isMobileScreensize } = useMobileScreensize();

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
  }));

  const handleConfirm = () => {
    setIsOpen(false);
    if (state?.handleConfirmClick) {
      state.handleConfirmClick();
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!state) {
    return <></>;
  }

  const { title, content } = state;

  return (
    <Dialog fullScreen={isMobileScreensize} open={isOpen} onClose={handleClose}>
      <div className="popup">
        <div className="title-container">
          <h3 className="title">{title}</h3>
        </div>
        <p className="content">{content}</p>
        <div className="popup-actions">
          <TextButton size="sm" onClick={handleClose}>
            {t("actions:Cancel")}
          </TextButton>
          <Button size="sm" onClick={handleConfirm}>
            {t("actions:Confirm")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
});

export default Popup;
