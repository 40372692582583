import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "normalize.css/normalize.css";

import { Profiler, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "store/store";

import { ThemeProvider } from "@mui/material/styles";

import "@crunchit/component-library/dist/app.css";

import "i18n";

import App from "./App";
import "assets/styles/index.scss";

import { Spinner } from "@crunchit/component-library";
import ErrorBoundary from "components/ErrorBoundary";

import theme from "mui";
import { onRender } from "utils/helpers/logs";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Profiler id="App" onRender={onRender}>
              <App />
            </Profiler>
          </ThemeProvider>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  </Provider>
);
