import { useTranslation } from "react-i18next";

import TableWrapper from "./TableWrapper";
import LoadingTableRows from "./Loading";
import TableRow from "./TableRow";
import TableColumn from "./TableColumn";

import "./Table.scss";

interface TableProps<T> {
  list: T[];
  title: string;
  detailColumns?: DetailColumn[];
  className?: string;
  isLoading?: boolean;
  isUnauthorized?: boolean;
  isError?: boolean;
  getListItemName: (item: T) => string | undefined;
  handleEditUpdate: (item: T) => void;
}

function Table<T extends { id: CrunchorderId }>({ list, title, className, isLoading, isUnauthorized, isError, getListItemName, detailColumns, handleEditUpdate }: TableProps<T>) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <TableWrapper className={className}>
        <LoadingTableRows />
      </TableWrapper>
    );
  }

  if (isUnauthorized) {
    return (
      <TableWrapper className={className}>
        <p className="empty-list-text">{t("common:Unauthorized")}</p>
      </TableWrapper>
    );
  }

  if (isError) {
    return (
      <TableWrapper className={className}>
        <p className="empty-list-text">{t("common:Error")}</p>
      </TableWrapper>
    );
  }

  if (!list.length) {
    return (
      <TableWrapper className={className}>
        <p className="empty-list-text">{t("common:EmptyList")}</p>
      </TableWrapper>
    );
  }

  return (
    <TableWrapper className={className}>
      <TableRow key="title-row">
        <TableColumn key="name-column">
          <h5 className="column-title">{title}</h5>
        </TableColumn>
        {detailColumns &&
          detailColumns.map(({ titleKey, subTitleContent }, index) => {
            return (
              <TableColumn key={`details-column${index}`}>
                <h5 className="column-title">{t(titleKey)}</h5>
                {subTitleContent}
              </TableColumn>
            );
          })}
      </TableRow>
      {list.map((listItem) => {
        return (
          <TableRow key={listItem.id} handleClick={() => handleEditUpdate(listItem)}>
            <TableColumn key={`${listItem.id}-name`}>
              <div className="column-title-container">
                <div className="border"></div>
                <h4 className="column-title">{getListItemName(listItem) || t("common:Unnamed")}</h4>
              </div>
            </TableColumn>
            {detailColumns &&
              detailColumns.map(({ content }, index) => {
                return <TableColumn key={`${listItem.id}-details${index}`}>{content(listItem)}</TableColumn>;
              })}
          </TableRow>
        );
      })}
    </TableWrapper>
  );
}

export default Table;
