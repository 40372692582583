import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ClickAwayListener, Fade } from "@mui/material";

import "./Prompt.scss";

interface CreateNewPromptProps<T> {
  options: T[];
  handleEditNew: (option?: T) => void;
  getOptionTitle: (option: T) => string | undefined;
  dismiss: () => void;
}

export default function CreateNewPrompt<T extends { id: CrunchorderId }>({ options, handleEditNew, getOptionTitle, dismiss }: CreateNewPromptProps<T>) {
  const { t } = useTranslation();

  const [isShowingOptions, setIsShowingOptions] = useState(false);

  let existingOptionsRef = useRef<HTMLDivElement>(null);
  let existingOptionsListRef = useRef<HTMLDivElement>(null);

  function handleClickAway() {
    dismiss();
  }

  function handleButtonClick(option?: T) {
    handleEditNew(option);
    handleClickAway();
  }

  useEffect(() => {
    let element = existingOptionsRef.current;
    let listElement = existingOptionsListRef.current;

    let isHovering = false;
    let hoverDelay = 500;
    let timer: any;

    const handleHideOptions = () => {
      if (!isHovering) {
        setIsShowingOptions(false);
      }
    };

    const handleHoverStart = () => {
      isHovering = true;
      setIsShowingOptions(true);
    };
    const handleHoverEnd = () => {
      isHovering = false;
      clearTimeout(timer);
      timer = setTimeout(handleHideOptions, hoverDelay);
    };

    if (element) {
      element.addEventListener("mouseover", handleHoverStart);
      element.addEventListener("mouseout", handleHoverEnd);
    }
    if (listElement) {
      listElement.addEventListener("mouseover", handleHoverStart);
      listElement.addEventListener("mouseout", handleHoverEnd);
    }

    return () => {
      clearTimeout(timer);

      if (element) {
        element.removeEventListener("mouseover", handleHoverStart);
        element.removeEventListener("mouseout", handleHoverEnd);
      }
      if (listElement) {
        listElement.removeEventListener("mouseover", handleHoverStart);
        listElement.removeEventListener("mouseout", handleHoverEnd);
      }
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="create-new-prompt-container">
        <div className="create-new-prompt prompt-box">
          <div className="prompt-option" onClick={() => handleButtonClick()}>
            <p className="option-text">{t("actions:CreateNewPrompt.FromNothing")}</p>
          </div>
          <div className="prompt-option" ref={existingOptionsRef}>
            <p className="option-text">{t("actions:CreateNewPrompt.FromExisting")}</p>
            <div className="option-icon"></div>
          </div>
        </div>
        <Fade in={isShowingOptions}>
          <div className="create-new-prompt-options prompt-box" ref={existingOptionsListRef}>
            {options.map((option, index) => {
              return (
                <div key={option.id} className="prompt-option" onClick={() => handleButtonClick(option)}>
                  <p className="option-text">{getOptionTitle(option) || `#${index + 1}`}</p>
                </div>
              );
            })}
          </div>
        </Fade>
      </div>
    </ClickAwayListener>
  );
}
