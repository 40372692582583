import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useAsyncGetRequest from "utils/hooks/http/useAsyncGetRequest";
import useEditNavigation from "utils/hooks/useEditNavigation";

import Edit from "./Edit";
import { TableView } from "components/ui/Table";

import detailColumns from "./DetailColumns";

import { sortAlphabetically } from "utils/helpers/misc";

export default function KDSView() {
  const { value: kdsInstances = [], valueIsLoading: isLoading, isUnauthorized, isError, updateValue: updateKdsInstances } = useAsyncGetRequest<KDSInstance[]>({ api: "KDS", path: "/Instance" });

  const { t } = useTranslation();

  const { toEdit, handleEditUpdate, handleEditNew, dismissEdit } = useEditNavigation(kdsInstances);

  useEffect(() => {
    updateKdsInstances();
  }, [updateKdsInstances]);

  const handleSuccessfulEdit = () => {
    dismissEdit();
    updateKdsInstances();
  };

  if (toEdit) {
    return <Edit goBackText={t("setup:KDS.TableTitle")} initialValue={toEdit} handleSuccessfulEdit={handleSuccessfulEdit} dismiss={dismissEdit} />;
  }

  return (
    <TableView
      title={t("setup:KDS.TableTitle")}
      list={kdsInstances.sort((a, b) => sortAlphabetically(a.settings.name, b.settings.name))}
      isLoading={isLoading}
      isUnauthorized={isUnauthorized}
      isError={isError}
      detailColumns={detailColumns}
      getListItemName={(instance: KDSInstance) => instance.settings.name}
      handleEditUpdate={handleEditUpdate}
      handleEditNew={handleEditNew}
    />
  );
}
