import Skeleton from "@mui/material/Skeleton";

import TableRow from "./TableRow";

export default function LoadingTableRows() {
  return (
    <>
      <TableRow>
        <Skeleton variant="rounded" sx={{ width: "0%" }} /> {/* Dummy header row */}
      </TableRow>
      <TableRow>
        <Skeleton variant="rounded" sx={{ margin: "16px 25px", width: "100%" }} />
      </TableRow>
      <TableRow>
        <Skeleton variant="rounded" sx={{ margin: "16px 25px", width: "100%" }} />
      </TableRow>
      <TableRow>
        <Skeleton variant="rounded" sx={{ margin: "16px 25px", width: "100%" }} />
      </TableRow>
    </>
  );
}
