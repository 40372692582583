import { useEffect, lazy } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router";

import Home from "components/home";
import Setup from "components/setup";
import { features } from "utils/constants/crunchorder";

const KDSView = lazy(() => import("components/setup/KDS"));
const LanguageView = lazy(() => import("components/setup/Language"));
const PrinterView = lazy(() => import("components/setup/Printer"));
const EpsonView = lazy(() => import("components/setup/Epson"));
const ComasysView = lazy(() => import("components/setup/Comasys"));
const MessageTypeView = lazy(() => import("components/setup/MessageType"));

const Modules = lazy(() => import("components/modules"));
const ModuleView = lazy(() => import("components/modules/Module"));

const Locations = lazy(() => import("components/locations"));

const Features = lazy(() => import("components/features"));
const FeatureView = lazy(() => import("components/features/Feature"));

export default function App() {
  const location = useLocation();

  // Handle scroll when a new page is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/setup" element={<Setup />}>
        <Route path="kds/:id?" element={<KDSView />} />
        <Route path="language/:id?" element={<LanguageView />} />
        <Route path="printer/:id?" element={<PrinterView />} />
        <Route path="comasys" element={<ComasysView />} />
        <Route path="epson" element={<EpsonView />} />
        <Route path="messagetype" element={<MessageTypeView />} />
      </Route>
      <Route path="/locations/:id?" element={<Locations />} />
      <Route path="/modules" element={<Modules />}>
        <Route path=":moduleType/:id?" element={<ModuleView />} />
      </Route>
      <Route path="/features" element={<Features />}>
        {features.map((featureName) => {
          return <Route key={featureName} path={`${featureName}/:id?`} element={<FeatureView key={featureName} featureName={featureName} />} />;
        })}
      </Route>

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}
