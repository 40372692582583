import { useTranslation } from "react-i18next";

import FormStatus from "./Status";
import TextButton from "../TextButton";
import { Button } from "@crunchit/component-library";

import "./Actions.scss";

type ActionSize = "sm" | "md";

interface FormActionProps extends FormStatusProps {
  formStatus?: FormStatusProps;
  size?: ActionSize;
  handleSaveClick?: () => void;
  handleDeleteClick?: () => void;
  handleCancelClick?: () => void;
}

export default function FormActions(props: FormActionProps) {
  const { formStatus, size, handleSaveClick, handleDeleteClick, handleCancelClick } = props;

  const { t } = useTranslation();

  return (
    <div className={`actions-section section-size-${size}`}>
      <FormStatus {...formStatus} />
      <div className="form-actions">
        {handleSaveClick !== undefined && (
          <Button size={size} onClick={handleSaveClick}>
            {t("actions:Save")}
          </Button>
        )}
        {handleCancelClick !== undefined && (
          <TextButton size={size} onClick={handleCancelClick}>
            {t("actions:Cancel")}
          </TextButton>
        )}
        {handleDeleteClick !== undefined && (
          <Button size={size || "sm"} theme="danger" className="delete-action-button" onClick={handleDeleteClick}>
            {t("actions:Delete")}
          </Button>
        )}
      </div>
    </div>
  );
}
