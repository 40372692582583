import { getNestedValue } from "./misc";

export const isValidInput = ({ type, value }: { type: string; value: any }) => {
  let isValid = true;
  switch (type) {
    case "text":
      isValid = true;
      break;
    case "number":
      isValid = !isNaN(parseFloat(value));
      break;
    case "email":
      let re = /\S+@\S+\.\S+/;
      isValid = re.test(value);
      break;
    default:
      isValid = true;
      break;
  }
  return isValid;
};

export function getPatchList({ keys, values }: { keys: string[]; values: Object }): RequestBody {
  return keys
    .map((patchKey: string) => {
      let key = patchKey as keyof typeof values;
      let value = getNestedValue(values, key);
      return { op: "replace", path: `/${patchKey}`, value: value };
    })
    .sort(({ value }) => (value === null ? -1 : 1));
}

/**
 * Compare original and updated settings objects and generate patch request body
 */
export function generatePatchList(params: { editedKeys: string[]; original?: Object; edited: Object; forceOneOfSetting?: string[] }): RequestBody {
  const { editedKeys: keys, original, edited, forceOneOfSetting } = params;

  if (!forceOneOfSetting || !original) {
    return getPatchList({ keys, values: edited });
  }

  const isChangedIntegrationSetting = (key: string) => {
    let originalValueExists = !!getNestedValue(original, key);
    let editedValueExists = !!getNestedValue(edited, key);
    return originalValueExists !== editedValueExists;
  };

  // If the selected integration setting hasn't changed, we patch as usual
  if (!forceOneOfSetting.find(isChangedIntegrationSetting)) {
    return getPatchList({ keys, values: edited });
  }

  let editedKeys = keys;
  forceOneOfSetting.forEach((integrationSettingKey) => {
    // Replacing the patch keys because its a new settings object
    editedKeys = editedKeys.filter((editedKey) => !editedKey.includes(integrationSettingKey));
    editedKeys.push(integrationSettingKey);
  });

  return getPatchList({ keys: editedKeys, values: edited });
}
