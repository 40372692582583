import { useState } from "react";
import { useTranslation } from "react-i18next";

import Table from "../Table";
import CreateNewPrompt from "./Prompt";
import { Button } from "@crunchit/component-library";

import useMobileScreensize from "utils/hooks/useMobileScreensize";

import "./View.scss";

interface TableViewProps<T> {
  title: string;
  list: T[];
  detailColumns?: DetailColumn[];
  className?: string;
  isLoading?: boolean;
  isUnauthorized?: boolean;
  isError?: boolean;

  getListItemName: (item: T) => string | undefined;
  handleEditUpdate: (item: T) => void;
  handleEditNew?: (item?: T) => void;
}

export default function TableView<T extends { id: CrunchorderId }>(props: TableViewProps<T>) {
  const { title, list, ...tableProps } = props;
  const { isLoading, isUnauthorized, isError, getListItemName, handleEditNew: editNewCallback } = tableProps;
  const { t } = useTranslation();

  const [isShowingPrompt, setIsShowingPrompt] = useState(false);

  const { isMobileScreensize } = useMobileScreensize();

  function handleEditNew(item?: T) {
    if (editNewCallback) {
      editNewCallback(item);
    }
  }

  function handleCreateButtonClick() {
    !isMobileScreensize && list.length > 0 ? setIsShowingPrompt(true) : handleEditNew();
  }

  return (
    <div className="table-view">
      <div className="table-title-wrapper">
        <h3 className="table-title">{`${t("common:ModalTitlePrepend")} ${title}`}</h3>
        {!isLoading && !isUnauthorized && !isError && editNewCallback && <Button size="xs" attributes={{ disabled: isLoading }} onClick={handleCreateButtonClick}>{`+ ${t("actions:New")}`}</Button>}
        {isShowingPrompt && <CreateNewPrompt options={list} getOptionTitle={getListItemName} handleEditNew={handleEditNew} dismiss={() => setIsShowingPrompt(false)} />}
      </div>
      <Table list={list} title={t("common:TableModalName")} {...tableProps} />
    </div>
  );
}
