import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFormControl } from "@mui/material/FormControl";

import { TextInput, CheckboxInput, NumberInput, SelectInput, DateInput, ToggleInput, RadioInput } from "./inputs";

import useInputValidation from "utils/hooks/forms/useInputValidation";

interface FormInputProps extends Omit<FormHook, "ref">, Omit<InputFieldProps, "label" | "defaultValue" | "handleInputChange"> {
  type: string;
  handleInputChange?: (change: FormInputChange) => void; // Optional to override default behaviour
}

const FormInput = forwardRef<FormInputRef, FormInputProps>((props, ref) => {
  const { name, type, getDefaultValue, handleInputChange } = props;

  const { t, i18n } = useTranslation();

  let { error: isShowingValidationErrors = false } = useFormControl() || {};

  // Ensuring default value never updates
  let [defaultValue] = useState(type === "checkbox" || type === "toggle" ? !!getDefaultValue(name) : getDefaultValue(name));
  let [value, setValue] = useState(defaultValue);

  const { withInputValidation, validateInputValue } = useInputValidation({ type, isRequired: props.required });

  const hasEdits = useMemo(() => value !== defaultValue, [value, defaultValue]);
  const hasError = useMemo(() => validateInputValue(value), [value, validateInputValue]);

  const isShowingError = useMemo(() => (hasEdits || isShowingValidationErrors) && hasError, [hasEdits, hasError, isShowingValidationErrors]);

  let handle: FormInputRef = {
    name,
    value,
    hasEdits,
    hasError,
  };
  useImperativeHandle(ref, () => handle);

  // Intermediary inputChange event handler, with validation
  const handleInputChangeValidation = useCallback(
    (change: FormInputChange) => {
      setValue(change.value);

      // If an override change handler is provided
      if (handleInputChange) {
        let validatedChange = withInputValidation(change);
        handleInputChange({ ...validatedChange, value: validatedChange.value });
      }
    },
    [handleInputChange, withInputValidation]
  );

  let overrideProps = useMemo(() => {
    // Excluding from all inputs
    const { type, translationKey, getDefaultValue, handleInputChange, ...inputFieldProps } = props;

    let inputName = props.name;

    let nestedKeys = inputName.split("/");
    let translationsName = nestedKeys[nestedKeys.length - 1];

    let override = {
      ...inputFieldProps,

      key: inputName,

      defaultValue,
      error: isShowingError,

      label: translationKey ? t(`${translationKey}.${translationsName}`, { defaultValue: translationsName }) : translationsName,
      ...(i18n.exists(`${translationKey}.${translationsName}HelperText`) === true && { helperText: t(`${translationKey}.${translationsName}HelperText`) }),
      ...(i18n.exists(`${translationKey}.${translationsName}Placeholder`) === true && { placeholder: t(`${translationKey}.${translationsName}Placeholder`) }),

      handleInputChange: handleInputChangeValidation,
    } as InputFieldProps;

    if (inputFieldProps.selectProps?.withOptions) {
      return inputFieldProps.selectProps.withOptions(override);
    }
    return override;
  }, [props, defaultValue, isShowingError, handleInputChangeValidation, t, i18n]);

  if (type === "text") {
    return <TextInput {...{ ...overrideProps }} />;
  }

  if (type === "number") {
    return <NumberInput {...{ ...overrideProps }} />;
  }

  if (type === "checkbox") {
    return <CheckboxInput {...overrideProps} />;
  }

  if (type === "toggle") {
    return <ToggleInput {...overrideProps} />;
  }

  if (type === "select" && overrideProps.selectProps) {
    return <SelectInput {...overrideProps} selectProps={overrideProps.selectProps} />;
  }

  if (type === "radio" && overrideProps.selectProps) {
    return <RadioInput {...overrideProps} selectProps={overrideProps.selectProps} />;
  }

  if (type === "date") {
    return <DateInput {...overrideProps} />;
  }

  return <></>;
});

export default FormInput;
