import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

import useTabScroll from "./useTabScroll";

import scrollIcon from "assets/img/icons/black/arrow-right.svg";

import "./Tabs.scss";

export default function NavigationTabs({ tabs }: { tabs: NavigationTab[] }) {
  let [activeTab, setActiveTab] = useState(0);

  const { pathname } = useLocation();
  const { id: paramsId } = useParams();

  const { t } = useTranslation();

  const { showLeftScrollButton, showRightScrollButton, handleScrollButtonClick } = useTabScroll();

  let activeTabPath = useMemo(() => pathname.replace(`/${paramsId}`, ""), [pathname, paramsId]);

  useEffect(() => {
    // Updating active tab if url doesn't match (e.g. after page refresh)
    if (tabs[activeTab].path !== activeTabPath) {
      let updatedTabIndex = tabs.findIndex((tab) => tab.path === activeTabPath);
      if (updatedTabIndex > -1) {
        setActiveTab(updatedTabIndex);
      }
    }
  }, [tabs, activeTabPath, activeTab]);

  return (
    <div className="navigation-tabs">
      <button className={`scroll-button left-scroll-button ${showLeftScrollButton ? "" : "hidden-scroll-button"}`} onClick={() => handleScrollButtonClick("left")}>
        <img src={scrollIcon} alt="" loading="lazy" decoding="async" />
      </button>
      <div className="tabs" id="tabs">
        {tabs.map(({ name, path, labelKey }, index) => {
          return (
            <Link key={name} className={`tab ${activeTab === index ? "active-tab" : ""}`} to={path}>
              {t(labelKey, { defaultValue: name })}
            </Link>
          );
        })}
      </div>
      <button className={`scroll-button right-scroll-button ${showRightScrollButton ? "" : "hidden-scroll-button"}`} onClick={() => handleScrollButtonClick("right")}>
        <img src={scrollIcon} alt="" loading="lazy" decoding="async" />
      </button>
    </div>
  );
}
