import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useDispatch } from "react-redux";
import { useAppSelector } from "store/app";
import { customerActions } from "store/customer";
import { useUserSelector } from "store/user";

import { Spinner } from "@crunchit/component-library";

import Sidebar from "components/ui/navigation/Sidebar";
import Header from "components/ui/navigation/Header";

import "./Page.scss";

function browserIsOutdated() {
  let ua = window.navigator.userAgent;
  let msie = ua.indexOf("MSIE ");
  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./); // eslint-disable-line
}

export default function Page(props: { titleKey: string; subheader?: ReactNode; children: ReactNode }) {
  const { titleKey, subheader, children } = props;

  const { t } = useTranslation();

  const { token, isLoading } = useAppSelector();
  const { sidebarIsOpen } = useUserSelector();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      dispatch(customerActions.resetState());
      navigate("/");
    }
  }, [token, dispatch, navigate]);

  useEffect(() => {
    if (browserIsOutdated()) {
      throw new Error("Unsupported browser");
    }
  }, []);

  return (
    <div className={`page ${sidebarIsOpen ? "with-expanded-navbar" : "with-collapsed-navbar"}`}>
      <Sidebar />

      <div className="page-view">
        <Header title={t(titleKey)} subheader={subheader} />
        {children}
        {isLoading && <Spinner />}
      </div>
    </div>
  );
}
