import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useCustomDispatch } from "store/useStore";

import { appActions, useAppSelector } from "store/app";
import { customerActions } from "store/customer";

import { Button } from "@crunchit/component-library";
import Form from "components/ui/Form/Form";
import { TextInput } from "components/ui/Form/inputs";

import logo from "assets/img/logos/logo.png";

import "./Home.scss";

export default function Home() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useCustomDispatch();

  const { token } = useAppSelector();

  const [searchParams] = useSearchParams();

  const [editedToken, setEditedToken] = useState(token);

  useEffect(() => {
    // Clearing stored token on mount
    dispatch(customerActions.resetState());
  }, [dispatch]);

  useEffect(() => {
    let tokenParam = searchParams.get("token");
    if (tokenParam) {
      dispatch(appActions.setToken(tokenParam));
      navigate("/setup");
    }
  }, [searchParams, dispatch, navigate]);

  const handleInputChange = useCallback(({ value }: FormInputChange) => {
    setEditedToken(value || "");
  }, []);

  // When a token is set, we redirect
  const handleSave = () => {
    dispatch(appActions.setToken(editedToken));
    navigate("/setup");
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (editedToken && event.key === "Enter") {
      handleSave();
    }
  };

  return (
    <div className="page home">
      <div className="home-title-container">
        <img className="logo" alt="logo" src={logo} />
        <h1 className="home-title">{t("common:AppTitle")}</h1>
      </div>

      <div className="home-modal">
        <div className="home-modal-header">
          <h3 className="home-modal-header-title">{t("home:ModalTitle")}</h3>
        </div>
        <div className="home-modal-body">
          <Form>
            <Form.Section title={t("home:FormTitle")}>
              <TextInput {...{ label: t("home:InputLabel"), name: "token", type: "text", defaultValue: token }} onKeyDown={handleKeyDown} handleInputChange={handleInputChange} />
            </Form.Section>
            <div className="actions-section">
              <div className="form-actions">
                <Button attributes={{ disabled: !editedToken }} onClick={handleSave}>
                  {t("actions:Continue")}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
