import { useCallback, useEffect, useMemo, useState } from "react";

import { useAppSelector } from "store/app";
import { customerActions, useCustomerSelector } from "store/customer";
import { useCustomDispatch } from "store/useStore";

import useSelectOptions from "../forms/useSelectOptions";

import { get } from "utils/helpers/http";

export default function useGroupTagNameOptions() {
  const { token } = useAppSelector();
  const { productInstanceId } = useCustomerSelector();

  let [loadingState, setLoadingState] = useState<LoadingState>();
  let [groupTags, setGroupTags] = useState<GroupTag[]>([]);

  const dispatch = useCustomDispatch();

  const loadGroupTags = useCallback(async () => {
    setLoadingState("loading");

    // Getting the product instance if we don't have it
    let instanceId = productInstanceId;
    if (!instanceId) {
      let productInstanceResponse = await get<ProductInstance[]>(token, "PRODUCT", "/Instance");
      if (!productInstanceResponse.isErrorResponse() && productInstanceResponse.data[0]?.id) {
        instanceId = productInstanceResponse.data[0]?.id;
        dispatch(customerActions.setProductInstanceId(instanceId));
      }
    }

    let groupTagResponse = await get<GroupTag[]>(token, "PRODUCT", `/GroupTag?instanceId=${instanceId}`);
    if (!groupTagResponse.isErrorResponse()) {
      setGroupTags(groupTagResponse.data);
    }

    setLoadingState("final");
  }, [token, productInstanceId, dispatch]);

  useEffect(() => {
    if (!loadingState) {
      loadGroupTags();
    }
  }, [loadingState, loadGroupTags]);

  // Mapping selected option to correct format for save
  const mapToValue = useCallback((value: any) => {
    let groupTag = value as GroupTag;
    return groupTag.name;
  }, []);

  // Get default selected option
  const mapDefaultValue = useCallback((value: any) => {
    return { name: value };
  }, []);

  const extendedSelectProps = useMemo(() => {
    const getOptionLabel = (option: GroupTag) => option.name || `${option.id}`;
    const isOptionEqualToValue = (option: GroupTag, val: GroupTag) => option.name === val.name;

    return {
      options: groupTags,
      loading: loadingState === "loading",
      getOptionLabel,
      isOptionEqualToValue,
    };
  }, [groupTags, loadingState]);

  return useSelectOptions({ extendedSelectProps, mapToValue, mapDefaultValue });
}
