import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isMobileScreen } from "./utils/helpers";

/* User interaction slice */

const initialState: UserStore = {
  numberOfRequestsPending: 0,
  error: false,

  sidebarIsOpen: !isMobileScreen(),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSidebarIsOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarIsOpen = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      let isLoading = action.payload;

      if (isLoading) {
        state.numberOfRequestsPending += 1;
      } else {
        state.numberOfRequestsPending = state.numberOfRequestsPending - 1 || 0;
      }
    },
  },
});

export const slice = userSlice.reducer;
export const actions = userSlice.actions;
