import { useCallback, useState } from "react";

import { useAppSelector } from "store/app";

import { get } from "utils/helpers/http";
import { API_URI } from "utils/constants/apis";
import { PosSystemType } from "utils/constants/crunchorder";

function withAllowedPosSystems(value: any) {
  const allowedPosSettingSystems = [PosSystemType.LAVU, PosSystemType.POSCONNECT, PosSystemType.ONLINEPOS];
  return value.filter((valueItem: any) => allowedPosSettingSystems.indexOf(valueItem.posSystem) > -1);
}

export default function useAsyncGetRequest<T>(requestProps: { api: API_URI; path: string }) {
  const { token } = useAppSelector();

  let [requestPropsOnMount] = useState(requestProps);

  let [requestState, setRequestState] = useState<RequestState>();
  let [loadingState, setLoadingState] = useState<LoadingState>();

  let [value, setValue] = useState<T>();

  const updateValue = useCallback(
    async (overrideRequestProps?: { api: API_URI; path: string }) => {
      if (!token) {
        return;
      }
      setLoadingState("loading");

      const { api, path } = overrideRequestProps || requestPropsOnMount;

      // TEMPORARY POSSETTING HACK

      if (api === "POSSETTING" && path === "/InstanceSetting") {
        let instanceResponse = await get<PosSettingInstance[]>(token, api, "/Instance");
        if (!instanceResponse.isErrorResponse()) {
          let posSettingInstanceList = withAllowedPosSystems(instanceResponse.data) as PosSettingInstance[];

          let instanceSettingResponses = await Promise.all(posSettingInstanceList.map(({ id }) => get<T>(token, api, `/InstanceSetting/${id}`)));
          let instanceSettingList = [] as T[];
          instanceSettingResponses.forEach((instanceSettingResponse) => {
            if (!instanceSettingResponse.isErrorResponse()) {
              instanceSettingList.push(instanceSettingResponse.data);
            }
          });
          setRequestState("success");
          setValue(instanceSettingList as T);
        }

        setLoadingState("final");
        return;
      }

      // END OF TEMPORARY POSSETTING HACK

      let response = await get<T>(token, api, path);
      if (!response.isErrorResponse()) {
        setRequestState("success");
        let responseValue = response.data;

        // Temporarily filtering possettings for specific POS systems
        if (api === "POSSETTING" && path === "/Instance") {
          responseValue = withAllowedPosSystems(responseValue) as T;
        }

        setValue(responseValue);
      } else {
        setRequestState(response.errorCode === 401 ? "unauthorized" : "error");
      }

      setLoadingState("final");
    },
    [token, requestPropsOnMount]
  );

  return { value, valueIsLoading: loadingState === "loading", isUnauthorized: requestState === "unauthorized", isError: requestState === "error", updateValue };
}
