import { ReactElement } from "react";

import { Tooltip, FormHelperText } from "@mui/material";

export default function InputWrapper({ name, helperText, children }: { name: string; helperText?: string; children: ReactElement }) {
  return (
    <div className="input-container">
      <Tooltip title={name} enterDelay={1000}>
        {children}
      </Tooltip>
      {<FormHelperText className="helper-text">{helperText}</FormHelperText>}
    </div>
  );
}
