import { useCallback, useState } from "react";
import { useAppSelector } from "store/app";
import { customerActions } from "store/customer";
import { useCustomDispatch } from "store/useStore";

import { API_URI } from "utils/constants/apis";
import { getPatchList } from "utils/helpers/forms";
import { post, patch, del } from "utils/helpers/http";

export default function useUpdateRequestFromInput(requestProps: { api: API_URI; path: string }) {
  const { token } = useAppSelector();

  let [requestPropsOnMount] = useState(requestProps);

  const dispatch = useCustomDispatch();

  const sendUpdateRequest = useCallback(
    async function <T extends { id: number | string }>({ editedValue, editedKeys }: { editedValue: Partial<T>; editedKeys: string[] }): Promise<HttpResponse<T>> {
      let response: HttpResponse<T>;

      dispatch(customerActions.setIsLoading(true));
      const { api, path } = requestPropsOnMount;
      const { id } = editedValue;

      // POST if no id
      if (!id) {
        response = await post<T>(token, api, path, editedValue);
      } else {
        // PATCH if id is set
        let patches = getPatchList({ keys: editedKeys, values: editedValue });
        response = await patch<T>(token, api, `${path}/${id}`, patches);
      }

      dispatch(customerActions.setIsLoading(false));
      return response;
    },
    [token, requestPropsOnMount, dispatch]
  );

  const sendDeleteRequest = useCallback(
    async function (id: CrunchorderId): Promise<HttpResponse<void>> {
      dispatch(customerActions.setIsLoading(true));
      const { api, path } = requestPropsOnMount;
      let response = await del<void>(token, api, `${path}/${id}`);
      dispatch(customerActions.setIsLoading(false));
      return response;
    },
    [token, requestPropsOnMount, dispatch]
  );

  return { sendUpdateRequest, sendDeleteRequest };
}
