import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Skeleton from "@mui/material/Skeleton";

import Edit from "./Edit";

import useAsyncGetRequest from "utils/hooks/http/useAsyncGetRequest";

export default function LanguageView() {
  const { value: language, valueIsLoading: isLoading, isUnauthorized, isError, updateValue: updateLanguage } = useAsyncGetRequest<LanguageSetting>({ api: "CUSTOMERSETTINGS", path: "/Language" });

  const { t } = useTranslation();

  useEffect(() => {
    updateLanguage();
  }, [updateLanguage]);

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        height={250}
        sx={{
          width: "clamp(300px, 90%, 100vw)",
          margin: "0 auto",
        }}
      />
    );
  }

  if (isUnauthorized || isError) {
    return <p style={{ textAlign: "center" }}>{isUnauthorized ? t("common:Unauthorized") : t("common:Error")}</p>;
  }

  return <Edit initialValue={language || {}} />;
}
