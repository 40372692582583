import { useTranslation } from "react-i18next";
import { useCustomDispatch } from "store/useStore";
import { useAppSelector } from "store/app";
import { customerActions } from "store/customer";

import Popup from "components/ui/Popup";
import EditModal from "components/ui/modals/EditModal";
import Form from "components/ui/Form";

import usePopup from "utils/hooks/usePopup";
import useFormInputs from "utils/hooks/forms/useFormInputs";
import useFormStatus from "utils/hooks/forms/useFormStatus";
import useUpdateRequestFromInput from "utils/hooks/http/useUpdateRequestFromInput";

import useModuleIdOptions from "utils/hooks/options/useModuleIds";
import useKdsViewOptions from "utils/hooks/options/useKdsViews";
import useModuleTypeOptions from "utils/hooks/options/useModuleTypes";
import useGroupTagNameOptions from "utils/hooks/options/useGroupTagNames";

import { languageOptions } from "utils/constants/options";
import { patch, post } from "utils/helpers/http";
import { getPatchList } from "utils/helpers/forms";

const translationKey = "setup:KDS.Form.InputLabels";

export default function Edit(props: EditProps<KDSInstance>) {
  const { initialValue, goBackText } = props;
  const { handleSuccessfulEdit, dismiss } = props;

  const { token } = useAppSelector();

  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  const { popupRef, popupState, showConfirmDismissPopup, showConfirmDeletePopup } = usePopup();
  const { formStatus, formStatusHandler } = useFormStatus();
  const { isShowingValidationErrors } = formStatus;
  const { inputProps, getEditedKeys, getInputErrors, getEditedValue } = useFormInputs({ initialValue: initialValue.settings || {}, translationKey });

  const moduleIdSelectProps = useModuleIdOptions();
  const kdsViewSelectProps = useKdsViewOptions();
  const moduleTypeSelectProps = useModuleTypeOptions();
  const groupTagSelectProps = useGroupTagNameOptions();

  const { sendDeleteRequest } = useUpdateRequestFromInput({ api: "KDS", path: "/Instance" });

  async function handleSave() {
    formStatusHandler.reset();
    if (getEditedKeys().length === 0) {
      handleSuccessfulEdit();
      return;
    }
    if (getInputErrors().length > 0) {
      formStatusHandler.setStatus({ isShowingValidationErrors: true });
      return;
    }

    dispatch(customerActions.setIsLoading(true));

    let editedValue = getEditedValue();
    // Will be just the settings - compile final kds instance before saving
    let editedKdsInstance: Partial<KDSInstance> = {
      ...(initialValue && { id: initialValue.id }),
      settings: editedValue as KDSInstanceSetting,
    };

    let response: HttpResponse<KDSInstance>;
    if (!editedKdsInstance.id) {
      response = await post<KDSInstance>(token, "KDS", "/Instance", editedValue);
    } else {
      let patches = getPatchList({ keys: ["settings"], values: editedKdsInstance });
      response = await patch<KDSInstance>(token, "KDS", `/Instance/${editedKdsInstance.id}`, patches);
    }

    dispatch(customerActions.setIsLoading(false));

    if (!response.isErrorResponse()) {
      handleSuccessfulEdit();
    } else {
      formStatusHandler.setStatus({ isError: true, statusText: response.errorText });
    }
  }

  async function handleDelete() {
    let id = initialValue.id as CrunchorderId;
    let response = await sendDeleteRequest(id);
    if (!response.isErrorResponse()) {
      handleSuccessfulEdit();
    } else {
      formStatusHandler.setStatus({ isError: true, statusText: response.errorText });
    }
  }

  function handleDismissEdit() {
    if (getEditedKeys().length) {
      showConfirmDismissPopup(dismiss);
    } else {
      dismiss();
    }
  }

  return (
    <EditModal title={initialValue.id ? initialValue.settings?.name || t("setup:KDS.FallbackModalTitle") : t(`setup:KDS.ModalTitle`)} goBackText={goBackText} handleBackClick={handleDismissEdit}>
      <Form isShowingValidationErrors={isShowingValidationErrors}>
        <Form.Section>
          <Form.Input name="name" type="text" required {...inputProps} />
          <Form.Input name="language" type="select" required selectProps={{ options: languageOptions }} {...inputProps} />
          <Form.Input name="kdsViewOption" type="select" required selectProps={{ ...kdsViewSelectProps }} {...inputProps} />
          <Form.Input name="modules" type="select" selectProps={{ multiple: true, ...moduleIdSelectProps }} {...inputProps} />
          <Form.Input name="moduleTypes" type="select" selectProps={{ multiple: true, ...moduleTypeSelectProps }} {...inputProps} />
          <Form.Input name="groupTagInclusions" type="select" selectProps={{ multiple: true, ...groupTagSelectProps }} {...inputProps} />
          <Form.Input name="groupTagExclusions" type="select" selectProps={{ multiple: true, ...groupTagSelectProps }} {...inputProps} />
        </Form.Section>

        <Form.Actions
          formStatus={formStatus}
          handleSaveClick={handleSave}
          handleCancelClick={handleDismissEdit}
          handleDeleteClick={initialValue.id ? () => showConfirmDeletePopup(handleDelete) : undefined}
        />
      </Form>
      <Popup ref={popupRef} state={popupState} />
    </EditModal>
  );
}
