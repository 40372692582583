import { useCallback, useEffect, useMemo, useState } from "react";

const mobileScreenWidthThreshold = 992;

export default function useMobileScreensize() {
  const mediaQueryWatcher = useMemo(() => {
    return window.matchMedia(`screen and (max-width: ${mobileScreenWidthThreshold}px)`);
  }, []);
  let [isMobileScreensize, setIsMobileScreensize] = useState(mediaQueryWatcher.matches);

  const onChange = useCallback((event: MediaQueryListEvent) => {
    const { matches } = event;
    setIsMobileScreensize(matches);
  }, []);

  useEffect(() => {
    try {
      mediaQueryWatcher.addEventListener("change", onChange);
    } catch (error) {}

    return () => {
      try {
        mediaQueryWatcher.removeEventListener("change", onChange);
      } catch (error) {}
    };
  }, [mediaQueryWatcher, onChange]);

  return {
    isMobileScreensize,
  };
}
