import { ReactNode } from "react";

import "./Modal.scss";

interface ModalProps {
  modalType: string;
  children: ReactNode;
}

function Modal({ modalType = "default", children }: ModalProps) {
  return <div className={`modal-container ${modalType}-modal`}>{children}</div>;
}

Modal.Header = ({ children }: { children: ReactNode }) => <div className="modal-header">{children}</div>;
Modal.Body = ({ children }: { children: ReactNode }) => <div className="modal-body">{children}</div>;
Modal.Footer = ({ children }: { children: ReactNode }) => <div className="modal-footer">{children}</div>;

export default Modal;
