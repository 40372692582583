import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useSelectOptions from "../forms/useSelectOptions";

import { KdsViewOption } from "utils/constants/crunchorder";

const isViewOption = (option: any): option is KdsViewOption => {
  return typeof option === "number";
};

export default function useKdsViewOptions() {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.values(KdsViewOption).filter(isViewOption);
  }, []);

  const extendedSelectProps = useMemo(() => {
    const getOptionLabel = (option: KdsViewOption) => {
      let viewOptionName = KdsViewOption[option];
      return t(`options:ViewOptions.${viewOptionName}`);
    };

    return {
      options,
      getOptionLabel,
    };
  }, [options, t]);

  return useSelectOptions({ extendedSelectProps });
}
