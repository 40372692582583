import { memo, useMemo, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import { Autocomplete, CircularProgress, TextField, Checkbox, AutocompleteRenderOptionState, FormControlLabel } from "@mui/material";

import InputWrapper from "./Wrapper";

function SelectInput(props: InputFieldProps & { selectProps: CustomAutocompleteProps }) {
  const { helperText, endAdornment, selectProps: allSelectProps, handleInputChange, ...inputProps } = props;

  // Excluding
  const { format, labelPlacement, placeholder, ...restProps } = inputProps;
  const { direction, ...selectProps } = allSelectProps;

  const { t } = useTranslation();

  const renderOption = useMemo(() => {
    const SelectOption = (props: HTMLAttributes<HTMLLIElement>, option: any, { index, selected }: AutocompleteRenderOptionState) => {
      let optionLabel = selectProps.getOptionLabel ? selectProps.getOptionLabel(option) : option;
      let optionKey = `${option.id || option}-${index}`;
      if (selectProps.multiple) {
        // Checkbox for multiple selects
        return (
          <li {...props} key={optionKey}>
            <FormControlLabel label={optionLabel} control={<Checkbox style={{ marginRight: 8 }} checked={selected} />} />
          </li>
        );
      }
      return (
        <li {...props} key={optionKey}>
          {optionLabel}
        </li>
      );
    };
    return SelectOption;
  }, [selectProps]);

  if (!selectProps.options) {
    return <></>;
  }

  // For autocomplete
  const { options, withOptions, getSelectedOptionValue, getSelectDefaultValue, ...selectInputProps } = selectProps;

  // For textfield
  const { defaultValue, readOnly, required, label, appendLabel, error, ...additionalSelectInputProps } = restProps;

  return (
    <InputWrapper name={props.name !== props.label ? props.name : ""} helperText={helperText}>
      <Autocomplete
        {...selectInputProps}
        {...additionalSelectInputProps}
        options={options}
        defaultValue={getSelectDefaultValue && defaultValue ? getSelectDefaultValue(defaultValue) : defaultValue}
        disabled={readOnly}
        disableCloseOnSelect={!!selectInputProps.multiple}
        loadingText={t("common:Fetching")}
        noOptionsText={t("common:NoOptions")}
        onChange={(event, newValue) => handleInputChange({ inputName: inputProps.name, value: getSelectedOptionValue ? getSelectedOptionValue(newValue) : newValue })}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={`${label}${appendLabel || ""}`}
            error={error}
            required={required}
            disabled={readOnly}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {selectProps.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {endAdornment}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </InputWrapper>
  );
}

export default memo(SelectInput);
