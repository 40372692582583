import { useTranslation } from "react-i18next";
import { KdsViewOption } from "utils/constants/crunchorder";

function ViewOption({ value }: { value: KDSInstance }) {
  const { t } = useTranslation();

  let viewOptionName = KdsViewOption[value.settings.kdsViewOption];
  return <p>{t(`options:ViewOptions.${viewOptionName}`)}</p>;
}

const detailColumns = [{ titleKey: "setup:KDS.TableColumnTitles.ViewOption", content: (value: KDSInstance) => <ViewOption value={value} /> }];

export default detailColumns;
