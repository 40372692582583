import { ReactNode } from "react";

interface TableRowProps {
  children: ReactNode[] | ReactNode;
  handleClick?: () => void;
}

export default function TableRow({ children, handleClick }: TableRowProps) {
  return (
    <div className="table-row" onClick={handleClick}>
      {children}
    </div>
  );
}
