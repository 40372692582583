import common from "./common.json";
import actions from "./actions.json";
import navigation from "./navigation.json";
import options from "./options.json";

import home from "./sections/home.json";
import setup from "./sections/setup.json";
import locations from "./sections/locations.json";
import modules from "./sections/modules.json";
import features from "./sections/features.json";

export const da = {
  common,
  actions,
  navigation,
  options,

  home,
  setup,
  locations,
  modules,
  features,
};
